<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="row p-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input type="radio" name="tabs" id="overviewtab" checked />
                <label for="overviewtab">OverView</label>
                <div class="tab">
                  <div class="form-row">
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      Class Name
                    </div>
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      LKG
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      Admin Teacher
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      Sumathy
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <button
                        type="button"
                        class="btn btnsml"
                        @click="openAssignTeacherPopUp"
                      >
                        Assign Teachers
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      No. of Students
                    </div>
                    <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                      120
                    </div>
                    <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                      Boys
                    </div>
                    <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                      70
                    </div>
                    <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                      Girls
                    </div>
                    <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                      50
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      Class Performance
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <button>Academics 80%</button>
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <button>Co-curricular 80%</button>
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <button>Attendance 80%</button>
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <button
                        type="button"
                        class="btn btnsml"
                        @click="openClassPerformance"
                      >
                        Class Performance
                      </button>
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="timetab" />
                <label for="timetab">TimeTable</label>
                <div class="tab">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="widjet">
                        <div class="widjethdr dispflex">
                          <div style="width: auto"></div>
                          <div class="dispflex">
                            <div style="width: 125px" class="flexitm">
                              <button
                                @click="openCreateTimeTablePopUp"
                                type="button"
                                class="btn btnsml"
                              >
                                <span>Create</span>
                              </button>
                            </div>
                            <div style="width: 200px" class="flexitm">
                              <button type="button" class="btn btnsml">
                                <span>Edit</span>
                              </button>
                            </div>
                            <div style="width: 200px" class="flexitm">
                              <button type="button" class="btn btnsml">
                                <span>Publish</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="widjetcontent">
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell">Time Periods</div>
                              <div class="cell">9:00 - 9:40<br />1</div>
                              <div class="cell">9:45 - 10:30<br />2</div>
                              <div class="cell">10:30 - 11:15<br />3</div>
                              <div class="cell">
                                11:15 - 11:30<br />Free Time
                              </div>
                              <div class="cell">11:30 - 12:00<br />4</div>
                              <div class="cell">12:00 - 12:45<br />5</div>
                              <div class="cell">
                                12:45 - 1:15<br />Free Time
                              </div>
                              <div class="cell">1:15 - 2:00<br />6</div>
                              <div class="cell">2:00 - 2:45<br />7</div>
                            </div>
                            <div
                              class="resrow"
                              v-for="(data, index) in periodList"
                              :key="index"
                            >
                              <div class="cell" data-title="From Month">
                                {{ data }}
                              </div>
                              <div class="cell" data-title="From Month">
                                English
                              </div>
                              <div class="cell" data-title="From Month">
                                Tamil
                              </div>
                              <div class="cell" data-title="From Month">
                                Hindi
                              </div>
                              <div class="cell" data-title="From Month">
                                Break
                              </div>
                              <div class="cell" data-title="From Month">
                                EVS
                              </div>
                              <div class="cell" data-title="From Month">PT</div>
                              <div class="cell" data-title="From Month">
                                Lunch
                              </div>
                              <div class="cell" data-title="From Month">
                                Biology
                              </div>
                              <div class="cell" data-title="From Month">
                                Physics
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="curriculumtab" />
                <label for="curriculumtab">Curriculum</label>
                <div class="tab">
                  <div class="form-row">
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      Terms
                    </div>
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      <select class="form-control form-input" id="deptlistid">
                        <option :value="null">-- Select --</option>
                      </select>
                    </div>
                    <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                      <button
                        type="button"
                        class="btn btnsml"
                        @click="openCurriculumPopUp"
                      >
                        Curriculum Setup
                      </button>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12 mt-3 pr-md-3">
                      <div class="admintbl pb-3 mt-1">
                        <div>
                          <div class="row cust-bg">
                            <div class="col-1 cell" style="padding-left: 2%">
                              Term
                            </div>
                            <div class="col-1 cell">Subject</div>
                            <div class="col-1 cell">Topics</div>
                            <div class="col-1 cell">No of Classes</div>
                            <div class="col-1 cell">Status</div>
                            <div class="col-1 cell">From Date</div>
                            <div class="col-1 cell">To Date</div>
                            <div class="col-1 cell">Action</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="assignmenttab" />
                <label for="assignmenttab">Assignments</label>
                <div class="tab" style="height: 382px">
                  <div class="row mt-2">
                    <div class="col-md-12 col-lg-12 col-sm-12">
                      <div class="widjet m-2">
                        <div class="widjethdr dispflex">
                          <div style="width: auto">
                            <span
                              class="iconsect blubg"
                              style="padding-left: 12px"
                              ><i class="fas fa-receipt"></i
                            ></span>
                            <h1>Assignments</h1>
                          </div>
                          <div style="margin-top: 10px" class="flexitm">
                            2 Assignment
                          </div>
                          <div style="margin-top: 10px" class="flexitm">
                            37 Submitted
                          </div>
                          <div style="margin-top: 10px" class="flexitm">
                            11 Completed
                          </div>
                          <div style="margin-top: 10px" class="flexitm">
                            20 Due
                          </div>
                           <div style="margin-top: 10px" class="flexitm">
                           <button style="width: 100%;" @click="assignmentView = 'Calender'" v-if="assignmentView == 'List'"
                              type="button"
                              class="btn btnsml"
                            >
                              Calender View
                            </button>
                             <button style="width: 100%;" v-else
                              type="button"
                              @click="assignmentView = 'List'"
                              class="btn btnsml"
                            >
                               List View
                            </button>
                          </div>
                           <div style="margin-top: 10px" class="flexitm">
                            <button style="width: 100%;"
                              type="button"
                              class="btn btnsml"
                            >
                              Add Assignments
                            </button>
                          </div>
                        </div>
                        <div class="tbllist mt-1" >
                          <div class="crtfeeplancont" style="padding: 0 13px">
                            <div
                              class="widjethdr dispflex"
                              style="align-items: baseline; padding: 10px 0"
                            ></div>
                            <div class="restable" v-if="assignmentView == 'List'">
                              <div class="resrow resheader">
                                <div class="cell">Assignment Name</div>
                                <div class="cell">Submitted</div>
                                <div class="cell">Due</div>
                                <div class="cell">Completed</div>
                                <div class="cell">Submit Date</div>
                                <div class="cell">Due Date</div>
                                <div class="cell">Action</div>
                              </div>
                              <div class="resrow">
                                <div class="cell">English</div>
                                <div class="cell">18</div>
                                <div class="cell">11</div>
                                <div class="cell">7</div>
                                <div class="cell">22-Jan-2022</div>
                                <div class="cell">11-Feb-2022</div>
                                <div class="cell">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                    <b-dropdown-item>Evaluate</b-dropdown-item>
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item>Delete</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                              </div>
                              <div class="resrow">
                                <div class="cell">Tamil</div>
                                <div class="cell">18</div>
                                <div class="cell">11</div>
                                <div class="cell">7</div>
                                <div class="cell">22-Jan-2022</div>
                                <div class="cell">11-Feb-2022</div>
                                <div class="cell">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                    <b-dropdown-item>Evaluate</b-dropdown-item>
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item>Delete</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                              </div>
                              <div class="resrow">
                                <div class="cell">Maths</div>
                                <div class="cell">18</div>
                                <div class="cell">11</div>
                                <div class="cell">7</div>
                                <div class="cell">22-Jan-2022</div>
                                <div class="cell">11-Feb-2022</div>
                                <div class="cell">
                                  <b-dropdown
                                    id="dropdown-1"
                                    text=""
                                    right
                                    style="margin-right: 3px"
                                  >
                                    <b-dropdown-item>Evaluate</b-dropdown-item>
                                    <b-dropdown-item>Edit</b-dropdown-item>
                                    <b-dropdown-item>Delete</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <FullCalendar :options="calendarOptions" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="examTab" />
                <label for="examTab">Exam Result</label>
                <div class="tab">
                  <div class="subtabs" style="height: 2%; background: none">
                    <input
                      type="radio"
                      name="subtabs"
                      id="AcademicsTab"
                      checked
                    />
                    <label for="AcademicsTab">Academics</label>
                    <div class="tab">
                      <div class="form-row">
                        <div
                          class="form-group input-group col-md-1 mt-3 pr-md-3"
                        >
                          Exam Term
                        </div>
                        <div
                          class="form-group input-group col-md-2 mt-3 pr-md-3"
                        >
                          Term 1
                        </div>
                        <div
                          class="form-group input-group col-md-2 mt-3 pr-md-3"
                        >
                          Subjects<select
                            class="form-control form-input"
                            id="deptlistid"
                          >
                            <option :value="null">-- Select --</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3 pr-md-3">
                        
                          <div class="widjetcontent tbllist">
                            <div class="restable">
                              <div class="resrow resheader">
                                <div class="cell">Term</div>
                                <div class="col-1 cell">Subject</div>
                                <div class="cell">Topics</div>
                                <div class="cell">Exam Date</div>
                                <div class="cell">Total Marks</div>
                                <div class="cell">Grade</div>
                                <div class="cell">%</div>
                                <div class="cell">Action</div>
                              </div>
                              <div class="resrow">
                                <div class="cell">Term 1</div>
                                <div class="col-1 cell">English</div>
                                <div class="cell">12/27/2022</div>
                                <div class="cell">85/100</div>
                                <div class="cell">A+</div>
                                <div class="cell">85%</div>
                                <div class="cell">%</div>
                                <div class="cell">
                                  <button
                                    type="button"
                                    style="width: 36%"
                                    class="btn btnsml"
                                    id="svbtn"
                                    @click="openResultPopUp"
                                  >
                                    Result
                                  </button>
                                </div>
                              </div>
                              <div class="resrow">
                                <div class="cell"></div>
                                <div class="col-1 cell">Tamil</div>
                                <div class="cell">12/27/2022</div>
                                <div class="cell">85/100</div>
                                <div class="cell">A+</div>
                                <div class="cell">85%</div>
                                <div class="cell">%</div>
                                <div class="cell">
                                  <button
                                    type="button"
                                    style="width: 36%"
                                    class="btn btnsml"
                                    id="svbtn"
                                    @click="openResultPopUp"
                                  >
                                    Result
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <input
                      type="radio"
                      name="subtabs"
                      id="CurricularActivities"
                    />
                    <label for="CurricularActivities"
                      >Curricular Activities</label
                    >
                    <div class="tab">
                      <div class="form-row">
                        <div
                          class="form-group input-group col-md-1 mt-3 pr-md-3"
                        >
                          Exam Term
                        </div>
                        <div
                          class="form-group input-group col-md-2 mt-3 pr-md-3"
                        >
                          Term 1
                        </div>
                        <div
                          class="form-group input-group col-md-2 mt-3 pr-md-3"
                        >
                          Subjects<select
                            class="form-control form-input"
                            id="deptlistid"
                          >
                            <option :value="null">-- Select --</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3 pr-md-3">
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell">Term</div>
                              <div class="cell">Activities</div>
                              <div class="cell">Date</div>
                              <div class="cell">Grade</div>
                              <div class="cell">Action</div>
                            </div>
                            <div class="resrow"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input type="radio" name="subtabs" id="Attendance" />
                    <label for="Attendance">Attendance</label>
                    <div class="tab">
                      <div class="form-row">
                        <div
                          class="form-group input-group col-md-1 mt-3 pr-md-3"
                        >
                          Exam Term
                        </div>
                        <div
                          class="form-group input-group col-md-2 mt-3 pr-md-3"
                        >
                          Term 1
                        </div>
                        <div
                          class="form-group input-group col-md-2 mt-3 pr-md-3"
                        >
                          Subjects<select
                            class="form-control form-input"
                            id="deptlistid"
                          >
                            <option :value="null">-- Select --</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3 pr-md-3">
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell">Student Name</div>
                              <div class="cell">Total School Days</div>
                              <div class="cell">Present</div>
                              <div class="cell">Absent</div>
                              <div class="cell">Grade</div>
                              <div class="cell">Action</div>
                            </div>
                            <div class="resrow"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="subtabs"
                      id="OverallPerformance"
                    />
                    <label for="OverallPerformance">Overall Performance</label>
                    <div class="tab">
                      <div class="form-row">
                        <div class="form-group col-md-12 mt-3 pr-md-3">
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell">Student Name</div>
                              <div class="cell">Academics</div>
                              <div class="cell">Curricular</div>
                              <div class="cell">Attendance</div>
                              <div class="cell">Ranking</div>
                              <div class="cell">Action</div>
                            </div>
                            <div class="resrow"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="assignTeacherPopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-calendar-alt"></i
                ></span>
                <h1>ASSIGN TEACHERS</h1>
              </div>
              <div style="margin-top: 10px">
                <input type="text" placeholder="Search Teacher" />
              </div>
              <div class="mt-1">
                <button class="btn gry_bg mpopup" v-b-modal.addacademicyr>
                  <i class="fas fa-calendar-plus"></i>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">From</div>
                  <div class="cell">To</div>
                  <div class="cell">Last Modified By</div>
                  <div class="cell">Last Modified Date</div>
                  <div class="cell">Action</div>
                </div>

                <div class="resrow"></div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                @click="closeAssignTeacherPopUp"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
              <button type="submit" class="btn btnsml" id="svbtn">
                  <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="classPerformancePopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-calendar-alt"></i
                ></span>
                <h1>STUDENT WISE</h1>
              </div>
              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  Term 1
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select class="form-control form-input" id="deptlistid">
                      <option :value="null">-- Select --</option>
                    </select>
                    <label for="clslistid">Select Academic Year </label>
                  </span>
                </div>
              </div>
            </div>
            <div class="widjetcontent tbllist">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Name</div>
                  <div class="cell">
                    <a href="javascript:void(0);">Academics </a>
                  </div>
                  <div class="cell">
                    <a href="javascript:void(0);">Curricular Activities </a>
                  </div>
                  <div class="cell">
                    <a href="javascript:void(0);">Attendance </a>
                  </div>
                </div>

                <div class="resrow"></div>
              </div>
            </div>
            <div class="widjetcontent tbllist">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Name</div>
                  <div class="cell">Term 1</div>
                  <div class="cell">Term 2</div>
                  <div class="cell">Term 3</div>
                  <div class="cell">Term 4</div>
                  <div class="cell">Action</div>
                </div>

                <div class="resrow"></div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                @click="closeClassPerformancePopUp"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="curriculumPopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div class="form-row">
                <div class="form-group input-group col-md-12 mt-3 pr-md-3">
                  No of terms : 3
                </div>
              </div>
            </div>

            <div class="widjetcontent tbllist">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Terms</div>
                  <div class="cell">Start Date</div>
                  <div class="cell">End Date</div>
                  <div class="cell">Duration in months</div>
                </div>

                <div class="resrow"></div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                class="btn btnsml"
                @click="closeCurriculumPopUp"
              >
                <span>Save</span>
              </button>
              <button
                type="button"
                @click="closeCurriculumPopUp"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="createTimeTablePopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto"></div>
              <div class="dispflex">
                <div style="width: 125px" class="flexitm">
                  <button
                    type="button"
                    @click="enableSetupTimeTable"
                    class="btn btnsml"
                    v-if="!showSetupTimeTable"
                  >
                    <span>SETUP TIME TABLE</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="showSetupTimeTable">
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Choose days
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Sun
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Mon
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Tue
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Wed
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Thu
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                Fri
              </div>
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                Sat
              </div>
              <div class="form-group input-group col-md-1 mt-3 pr-md-3">
                <a
                  style="padding-left: 6px"
                  href="javascript:void(0);"
                  @click="closeSetupTimeTable"
                  >Close</a
                >
              </div>
            </div>
            <div class="row" v-if="showSetupTimeTable">
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                Start Time :
                <input type="time" class="form-control form-input" />
              </div>
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                End Time : <input type="time" class="form-control form-input" />
              </div>
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                No. of Periods:
                <input
                  type="number"
                  min="3"
                  max="10"
                  class="form-control form-input"
                />
              </div>
            </div>
            <div class="widjetcontent">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Time</div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                  <div class="cell">
                    <input type="time" class="form-control form-input" />
                  </div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in periodList"
                  :key="index"
                >
                  <div class="cell" data-title="From Month">
                    {{ data }}
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                  <div class="cell" data-title="From Month">
                    <input type="text" class="form-control form-input" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="resultPopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div class="form-row" style="width: 100%">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <input
                    type="text"
                    style="margin-left: 4%"
                    placeholder="Search Students"
                  />
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Contribution
                  </button>
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Calculate
                  </button>
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>

            <div class="widjetcontent tbllist">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">S.No</div>
                  <div class="cell">Student Name</div>
                  <div class="cell">Grade</div>
                  <div class="cell">%</div>
                  <div class="cell">Ranks</div>
                  <div class="cell">Marks</div>
                  <div class="cell">Absent/Present</div>
                  <div class="cell">Comments</div>
                </div>

                <div class="resrow">
                  <div class="cell">1</div>
                  <div class="cell">Saran</div>
                  <div class="cell">A+</div>
                  <div class="cell">100%</div>
                  <div class="cell">1</div>
                  <div class="cell">99</div>
                  <div class="cell">Present</div>
                  <div class="cell">
                    <textarea
                      name="comment"
                      class="form-control form-input"
                      style="min-height: 40px; resize: none"
                      id="tocmts"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                @click="closeResultPopUp"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
  name: "teacher",
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: false,
        events: [{ title: "Meeting", start: new Date() }],
      },
      assignmentView: 'List',
      showSetupTimeTable: false,
      periodList: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      createPeriodList: [
        {
          day: "Mon",
        },
        {
          day: "Tue",
        },
        {
          day: "Wed",
        },
        {
          day: "Thu",
        },
        {
          day: "Fri",
        },
        {
          day: "Sat",
        },
      ],
    };
  },
  created() {},
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    openAssignTeacherPopUp() {
      this.$bvModal.show("assignTeacherPopUp");
    },
    closeAssignTeacherPopUp() {
      this.$bvModal.hide("assignTeacherPopUp");
    },
    openResultPopUp() {
      this.$bvModal.show("resultPopUp");
    },
    closeResultPopUp() {
      this.$bvModal.hide("resultPopUp");
    },

    openClassPerformance() {
      this.$bvModal.show("classPerformancePopUp");
    },
    closeClassPerformancePopUp() {
      this.$bvModal.hide("classPerformancePopUp");
    },
    openCurriculumPopUp() {
      this.$bvModal.show("curriculumPopUp");
    },
    closeCurriculumPopUp() {
      this.$bvModal.hide("curriculumPopUp");
    },
    openCreateTimeTablePopUp() {
      this.$bvModal.show("createTimeTablePopUp");
    },
    closeCreateTimeTablePopUp() {
      this.$bvModal.hide("createTimeTablePopUp");
    },
    enableSetupTimeTable() {
      this.showSetupTimeTable = true;
    },
    closeSetupTimeTable() {
      this.showSetupTimeTable = false;
    },
  },
  components: {
    FullCalendar,
  },
};
</script>

